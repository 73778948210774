import styles from "./index.module.css";
import cm from "classnames";
import { forwardRef, useEffect, useState } from "react";

const Index = (props, ref) => {
  const {
    placeholder = "请输入",
    addonAfter = "",
    onChange,
    type = "text",
    className = "",
    value,
  } = props;
  const [active, setActive] = useState(false);
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (value) {
      pwdShow();
    }
  }, []);

  const pwdHide = () => {
    setActive(false);
    setInputType("password");
  };
  const pwdShow = () => {
    setActive(true);
    setInputType("text");
  };
  return (
    <div className={cm(styles.input, className)}>
      <input
        ref={ref}
        type={inputType}
        placeholder={placeholder}
        onInput={(e) => {
          let upValue = e.currentTarget.value.replaceAll(/\s/g, "");
          onChange?.(upValue);
        }}
        autoComplete="off"
        defaultValue={value}
      />
      {type === "text" ? (
        <div className={styles.addon}>{addonAfter}</div>
      ) : null}
      {type === "password" ? (
        <div className={styles.addon}>
          {active ? (
            <img
              loading="lazy"
              src="/img/show.png"
              className={styles.inputImg}
              alt="显示"
              onClick={pwdHide}
            />
          ) : (
            <img
              loading="lazy"
              src="/img/hide.png"
              className={styles.inputImg}
              alt="隐藏"
              onClick={pwdShow}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default forwardRef(Index);
