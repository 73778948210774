import styles from "./index.module.css";
import { useRef, useState, useEffect } from "react";
import { useCountDown, encryptParams } from "./utils";
import Input from "./input";
import { Button, message } from "antd";
import cm from "classnames";
import { signIn, sendCode } from "./login";
import { Link } from "react-router-dom";
import copy from "copy-to-clipboard";

const pattern = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/;

const Home = (props) => {
  const submitRef = useRef(null);
  const { inviter } = props;
  const [acceptEmail, setAcceptEmail] = useState(false); //是否接收来自华视界的邮件
  const [firstPwd, setFirstPwd] = useState("");
  const [secondPwd, setSecondPwd] = useState("");
  const [second, isStart, startRun] = useCountDown(60);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState({
    pass: true,
    msg: "",
  });
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [showEmailError, setEmailError] = useState(false);

  const inputRef = useRef(null);
  // const cncryptionCipher = (data) => {
  //   const encryptedString = encryptAES(data, "HVTS9NFVIeWdUxbf");
  //   return encryptedString;
  // };
  //弹窗
  const checkEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  // 发送验证码
  const getCode = () => {
    if (email === "" || firstPwd === "" || secondPwd === "") return;
    const num = count + 1;
    setCount(num);
    startRun();
    if (count >= 1) {
      setShow(true);
    }
    sendCode(email);
  };
  // 验证输入密码的合法性
  const isValid = async () => {
    const passd = pattern.test(firstPwd);
    if (!passd) {
      return {
        pass: false,
        msg: "密码格式有误!!!",
      };
    }
    if (firstPwd !== secondPwd) {
      return {
        pass: false,
        msg: "两次密码不一致!!!",
      };
    }
    if (!code) {
      return {
        pass: false,
        msg: "验证码无效",
      };
    }

    return {
      pass: true,
    };
  };

  // 提交注册
  const submit = async () => {
    if (isLoading) {
      return;
    }
    const errors = await isValid();
    setError(errors);
    if (!errors.pass) {
      return;
    }
    setLoading(true);
    handleLogin();
    // EventBus.emit('toggleRobot', false, true)
  };

  // 已经领取过了
  const sendError = () => {
    message.info("注册成功,请前往官网使用");
  };

  // 超时处理
  const timeout = (ms) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms, { msg: "请求超时，请稍后重试!" });
    });
  };

  //验证注册
  const handleLogin = async () => {
    const ydtoken = "testing"; //await getYiDunToken()
    const params = {
      // acceptEmail: acceptEmail,
      email: email,
      code: code,
      password: firstPwd, //cncryptionCipher(firstPwd),
      // ydtoken
    };
    // 如果存在邀请人id，则填入数据
    const inviteId = inviter;
    if (inviteId) {
      params.inviter = parseInt(inviteId);
    }

    console.log("======= 要加密得信息 ============", params);
    const { body, headers } = await encryptParams(params);
    const res = await Promise.race([
      signIn(body, { headers }),
      timeout(1000 * 30),
    ]);
    setLoading(false);
    if (typeof res.code === "undefined") {
      setError({
        pass: false,
        msg: res?.msg,
      });
      return;
    }
    if (!res.code) {
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("email", email);

      // console.log("======= 注册 =====",res.data)
      // isNew === true  不弹窗
      if (!res.data.user.isNew) {
        sendError(true);
        return;
      }

      message.success("恭喜你，注册成功！");
      setShow(true);
      // openSendVip()
      // const scoreRes = await getPublishSource()
      // if (scoreRes?.data?.minePoints > 0) {
      //   openSendVip()
      // } else {
      //   sendError(true)
      // }
    } else {
      setSuccess(false);
      setError({
        pass: false,
        msg: res?.msg || "注册失败，请稍后重新尝试。",
      });
    }
  };

  useEffect(() => {
    const enterClick = (e) => {
      if (e.keyCode === 13) {
        submitRef.current.click();
      }
    };
    window.addEventListener("keydown", enterClick);

    // 提交注册

    setSuccess(true);

    return () => {
      window.removeEventListener("keydown", enterClick);
    };
  }, []);

  const addonCode = isStart ? (
    <span className={styles.codeText}>{second}s后可重发</span>
  ) : (
    <span className={styles.getCode} onClick={getCode}>
      获取验证码
    </span>
  );

  return (
    <div>
      <div className={styles.top}>华视界用户注册</div>
      <div className={styles.signIn}>
        <p className={styles.setPwd}>设置邮箱</p>
        <Input
          placeholder="请输入您的邮箱"
          ref={inputRef}
          onChange={(data) => {
            if (checkEmail(data)) {
              setEmail(data);
              setEmailError(false);
            } else setEmailError(true);
          }}
        />
        {showEmailError ? (
          <p className={styles.errorInfo}>请输入正确格式的邮箱</p>
        ) : null}
        <p className={styles.setPwd}>设置密码</p>
        <p className={styles.tips}>
          提示：须是6-20个英文字母、数字，且以上两种必须都包含
        </p>
        <Input
          placeholder="请输入密码"
          type="password"
          className={styles.pwd}
          onChange={(data) => setFirstPwd(data)}
        />
        <Input
          placeholder="请再次输入密码"
          type="password"
          onChange={(data) => setSecondPwd(data)}
        />
        <p className={styles.checkEmail}>
          邮箱验证
          <div className={cm(styles.fc, styles.open)}>
            <Link href={"https://t.me/+CKkE1hC7_3dlYTk1"}>
              <p className={styles.tag}>获取验证码有问题或无法注册? </p>
              {/* <p className={styles.open}>点击进群</p> */}
            </Link>
            <div className={cm(styles.show, "hideWhenPhone")}>
              <img
                src="/img/join_phone.png"
                className={styles.joinImg}
                alt="加入群聊"
              />
            </div>
          </div>
        </p>

        <Input
          placeholder="输入验证码"
          type="text"
          addonAfter={addonCode}
          onChange={(data) => setCode(data)}
        />
        {isStart ? (
          <p className={styles.tips} style={{ marginTop: 8, marginBottom: 4 }}>
            提示：验证码将发送至您的邮箱，15分钟内有效
          </p>
        ) : null}
        {/* {true ? (
        <>
          <div className={cm(styles.fc, styles.open)}>
            <p>获取验证码有问题或无法注册? </p>
            <Link href={'https://t.me/+CKkE1hC7_3dlYTk1'}>
              <p className={styles.open}>联系我们</p>
            </Link>
            <div className={cm(styles.show, 'hideWhenPhone')}>
              <img src="/api/images/join_phone.png" className={styles.joinImg} alt="加入群聊" />
            </div>
          </div>
        </>
      ) : (
        <></>
      )} */}

        {!error.pass ? <p className={styles.error}>{error.msg}</p> : null}
        <button
          hover="opacity"
          ref={submitRef}
          className={cm(styles.btn, isLoading ? styles.btnLoading : "")}
          onClick={submit}
        >
          {isLoading ? "账户创建中..." : "创建账户"}
        </button>
        {/* <p className={styles.agree}>
        <span
          className={cm(styles.radio, { [styles.radioActive]: acceptEmail })}
          onClick={() => setAcceptEmail(!acceptEmail)}
        ></span>
        <span>不接收华视界的精彩内容推荐邮件</span>
      </p> */}
        {show && (
          <div className={styles.show}>
            <div>https://hlove.tv</div>
            <Button
              className={styles.btn}
              onClick={() => {
                window.location.href = "https://hlove.tv";
              }}
            >
              立即跳转
            </Button>
            <Button
              className={styles.btn}
              onClick={() => {
                copy("https://hlove.tv");
                message.success("复制成功");
              }}
            >
              复制链接
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
